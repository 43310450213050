import React from 'react';
import Link from '@mui/icons-material/Link';

const FacilityManagement = () => {
  return (
    <section className='center-section'>
        <a href="http://sama-tech.ae/fm" className='center-link'>
            <Link style={{ fontSize: 32, color: 'var(--bg)' }} />
            <span>Go to Facility Management</span>
        </a>
    </section>
  );
};

export default FacilityManagement;