import icons from '../icons';
import { Link } from 'react-router-dom';
import './pages.css';
import { useState } from 'react';

const Products = () => {
    const [isEnter, setIsEnter] = useState(false);

    return (
        <>
            <section className='gradient-inverse'>
                <h1>Products</h1>
                <h1 className='x-large-text'>Our Products & Services</h1>
                <div className='section-content-part'>
                    <h2 style={{ width: '60%' }}>With a focus on <span className='different'>innovation, reliability,
                        and customer satisfaction, our offerings
                        empower businesses to achieve greater
                        efficiency, sustainability, and success.</span></h2>
                    <strong style={{ width: '60%', margin: '20px 0' }} className='different'>
                        Our range of innovative products and services includes:</strong>
                    <p style={{ width: '60%', marginTop: 20 }}>
                        <span className='different' style={{ fontWeight: 500 }}>
                            Air Conditioning and Gas Management System (ACGMS)</span><br />
                        Designed to optimize indoor climate control and enhance energy efficiency, the
                        ACGMS provides precise management of air quality and humidity levels.
                    </p>
                    <p style={{ width: '60%', marginTop: 20 }}>
                        <span className='different' style={{ fontWeight: 500 }}>
                            Water Quality Management</span><br />
                        Monitoring pH and Chlorine Levels for precise water quality management, ensuring safety and compliance in various applications.
                    </p>
                    <p style={{ width: '60%', marginTop: 20 }}>
                        <span className='different' style={{ fontWeight: 500 }}>
                            Efficient Energy Management</span><br />
                        Our Power Consumption Tracking System allows for real-time monitoring and
                        management of energy use, driving down costs and carbon footprint.
                    </p>
                    <p style={{ width: '60%', marginTop: 20 }}>
                        <span className='different' style={{ fontWeight: 500 }}>
                            Hardware Customization and Development Services</span><br />
                        Tailored solutions to meet specific client needs, from modification of existing
                        equipment to the design of new hardware systems.
                    </p>
                    <p style={{ width: '60%', marginTop: 20 }}>
                        <span className='different' style={{ fontWeight: 500 }}>
                            RFID Solutions and Asset Tagging</span><br />
                        Advanced RFID technology for seamless asset management, enhancing the visibility and control of organizational resources.
                    </p>
                    <p style={{ width: '60%', marginTop: 20 }}>
                        Through our dedication to cutting-edge technology, we provide tailored solutions that streamline asset management processes and power conservation, all
                        while offering the dependable service that our clients deserve.
                    </p>
                </div>
            </section>
            <section>
                <h1>Products</h1>
                <div className='section-content-part' style={{ width: '60%', placeItems: 'start' }}>
                    <h1 style={{ paddingBottom: 12 }}>Innovative Healthcare Solutions for <span className='different'>
                        Modern Medical Environments</span></h1>
                    <p style={{ margin: '20px 0', lineHeight: 1.5 }}>At JSR AL SAMA, we are committed to enhancing healthcare efficiency through cutting-edge solutions that streamline operations and ensure the highest levels of patient care.
                        The innovative products, such as medical consumables and medication dispensing machines, empower healthcare facilities to optimize resource utilization, reduce errors,
                        and improve overall service delivery.</p>
                    <h3 className='different' style={{ padding: '12px 0' }}>Key Solutions:</h3>
                    <ul style={{ paddingLeft: 20 }}>
                        <li style={{ marginTop: 12 }}><p>
                            <strong>Medication Dispensing Machines:</strong> Automating the storage, dispensing, and management of medication, ensuring accuracy, reducing waste, and enhancing patient safety. These systems are designed to meet the rigorous standards of modern healthcare facilities,
                            facilitating real-time inventory management and minimizing human errors.
                        </p></li>
                        <li style={{ marginTop: 12 }}><p>
                            <strong>Medical Consumables Management:</strong> The system provides seamless tracking and management of consumables,
                            reducing stockouts, and ensuring that critical supplies are always available when needed.
                        </p></li>
                    </ul>
                </div>
            </section>
            <section className='dark-section compare'>
                <h1>Products</h1>
                <h1 className='large-text'>Click on an <span className='different'>
                    ICON</span> to see <span className='different'>
                        SERVICE</span></h1>
                <div className='section-head'>
                    <ul className='navigation'>
                        <li><Link to={'/products-and-services/ph-and-chlorine'}>
                            pH & Chlorine
                        </Link></li>
                        <li><Link to={'/products-and-services/acgms'}>
                            ACGMS
                        </Link></li>
                        <li><Link to={'/products-and-services/power-consumption'}>
                            Power Consumption
                        </Link></li>
                        <li><Link to={'/products-and-services/hardware-customization-and-development'}>
                            Hardware Customization
                        </Link></li>
                        <li><Link to={'/products-and-services/rfid-asset-tracking'}>
                            RFID
                        </Link></li>
                    </ul>
                    <div className='section-content-part' style={{ margin: '6rem 0' }}>
                        <div className='router-container' style={{
                            position: 'relative', animation: 'rotate 20s linear infinite',
                            animationPlayState: isEnter ? 'paused' : 'running'
                        }}
                            onMouseEnter={() => setIsEnter(true)} onMouseLeave={() => setIsEnter(false)}>
                            <div className='router-container child'>
                                <div className='router-container child'>
                                    <div className='router-container' style={{ width: '65%' }}>
                                        <img style={{ width: '100%' }} alt='' src={icons.central} />
                                    </div>
                                </div>
                            </div>
                            <Link to={'/products-and-services/ph-and-chlorine'} className='section-icon icon-1'
                                style={{ gap: 4, position: 'absolute', top: '-12px', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <img style={{ width: 80 }} alt='' src={icons.chlorine} />
                                <div>
                                    <strong>pH and Chlorine</strong>
                                    <p className='small-text'>Monitoring System</p>
                                </div>
                            </Link>
                            <Link to={'/products-and-services/acgms'} className='section-icon icon-2'
                                style={{ gap: 4, flexDirection: 'row', position: 'absolute', top: '30%', left: '0%', transform: 'translateX(-50%)' }}>
                                <img style={{ width: 80 }} alt='' src={icons.system} />
                                <div>
                                    <strong>ACGMS</strong>
                                    <p className='small-text'>Systems</p>
                                </div>
                            </Link>
                            <Link to={'/products-and-services/power-consumption'} className='section-icon  icon-3'
                                style={{ gap: 4, flexDirection: 'row-reverse', position: 'absolute', top: '25%', left: '100%', transform: 'translateX(-50%)' }}>
                                <img style={{ width: 80 }} alt='' src={icons.power} />
                                <div>
                                    <strong>Power Consumption</strong>
                                    <p className='small-text'>Tracking System</p>
                                </div>
                            </Link>
                            <Link to={'/products-and-services/hardware-customization-and-development'}
                                className='section-icon'
                                style={{ gap: 4, flexDirection: 'row', position: 'absolute', top: '75%', left: '10%', transform: 'translateX(-20%)' }}>
                                <img style={{ width: 80 }} alt='' src={icons.hardware} />
                                <div>
                                    <strong>Hardware</strong>
                                    <p className='small-text'>Customization & Development Services</p>
                                </div>
                            </Link>
                            <Link to={'/products-and-services/rfid-asset-tracking'} className='section-icon' style={{
                                width: '50%', gap: 4, flexDirection: 'row-reverse', position: 'absolute', top: '80%', left: '90%', transform: 'translateX(-60%)'
                            }}>
                                <img style={{ width: 80 }} alt='' src={icons.remote} />
                                <div>
                                    <strong>RFID</strong>
                                    <p className='small-text'>Asset Tracking Solutions</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Products;